import isBrowser from './isBrowser';

const isIE11 = isBrowser &amp;&amp; !!(window.MSInputMethodContext &amp;&amp; document.documentMode);
const isIE10 = isBrowser &amp;&amp; /MSIE 10/.test(navigator.userAgent);

/**
 * Determines if the browser is Internet Explorer
 * @method
 * @memberof Popper.Utils
 * @param {Number} version to check
 * @returns {Boolean} isIE
 */
export default function isIE(version) {
  if (version === 11) {
    return isIE11;
  }
  if (version === 10) {
    return isIE10;
  }
  return isIE11 || isIE10;
}
